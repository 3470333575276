
import apple from '../assets/apple.png'
import playstore from '../assets/playstore.png'
import halfCircles from '../assets/halfCircles.png'
import restoCategory from '../assets/restoCategory.png'
import moviecategory from '../assets/movieCategory.png'
import leisureExtended from '../assets/leisureExtended.png'
import qrCode from '../assets/qrCode.png'
import { useTranslation } from 'react-i18next'

export function IndexPage() {

    const { t, } = useTranslation();

    return (
        <div className='indexPage'>
            <h1>{t('indexPage.title')}</h1>
            <p>{t('indexPage.description')}</p>

            <div className='downloadButtonContainer'>
                <a href='https://onelink.to/akm6jf'>
                    <img src={apple} alt='logo apple'></img>
                    <p>Apple Store</p>
                </a>
                <a href='https://onelink.to/akm6jf'>
                    <img src={playstore} alt='logo playstore'></img>
                    <p>Play Store</p>
                </a>
            </div>
            <img src={qrCode} alt='qrCode'></img>
            <div className='appScreenshots'>
                <img draggable={false} src={moviecategory} alt='Categorie movie'></img>
                <img draggable={false} src={leisureExtended} alt='Leisure extended'></img>
                <img draggable={false} src={restoCategory} alt='Categorie resto'></img>
            </div>
            <img className='circleBackground' draggable={false} src={halfCircles} alt='circles'></img>

        </div>
    )
}