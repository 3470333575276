import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

i18n
    // detect user language
    // learn more: https://github.com/i18next/i18next-browser-languageDetector
    .use(LanguageDetector)
    // pass the i18n instance to react-i18next.
    .use(initReactI18next)
    // init i18next
    // for all options read: https://www.i18next.com/overview/configuration-options
    .init({
        debug: true,
        fallbackLng: 'en',
        interpolation: {
            escapeValue: false, // not needed for react as it escapes by default
        },
        resources: {
            en: {
                translation: {
                    indexPage: {
                        title: 'Hello World',
                    },
                    invitePage: {
                        invite: "You're invited to join the hub",
                        joinHub: "Join",
                        urlExpired: "This invitation link has expired.",
                        urlCopied: "The link has been copied"
                    },
                    buttons: {
                        download: "Download",
                    },
                    privacyPolicy: {
                        title: "Privacy Policy",
                        description: "This Privacy Policy describes how LeisureTime collects, uses, and shares personal information of users of our application.",
                        informationCollected: "Information Collected",
                        informationCollectedDescription: "We may collect the following information when you use our application:",
                        userProvidedInfo: "User-provided information: When using the application, you may provide us with information such as your name, email address, and other personal information you choose to share.",
                        usageInfo: "Usage information: We may collect information about how you use the application, such as the activities you organize, the comments you leave, and other interactions with the application.",
                        informationSharing: "Information Sharing",
                        informationSharingDescription: "We do not share your personal information with third parties for commercial purposes. The information you provide will only be used for the operation of the LeisureTime application.",
                        informationSecurity: "Information Security",
                        informationSecurityDescription: "We take reasonable security measures to protect your information from unauthorized access, disclosure, alteration, or destruction.",
                        policyChanges: "Changes to this Privacy Policy",
                        policyChangesDescription: "We reserve the right to modify this Privacy Policy at any time. Changes will take effect upon posting on this page.",
                        consent: "By using our application, you consent to our Privacy Policy. If you have any questions or concerns regarding this Privacy Policy, please contact us at info@leisuretime.fr. This Privacy Policy was updated on 04/18/2024."
                    }

                }
            },
            fr: {
                translation: {
                    indexPage: {
                        title: 'Des loisirs entre amis',
                        description: "Leisure Time permet de planifier et organiser vos loisirs entre amis. Créez des hubs pour regrouper vos cercles sociaux, ajoutez des loisirs dans différentes catégories, et interagissez en commentant et en partageant des activités."
                    },
                    invitePage: {
                        invite: "T'invites à rejoindre le hub",
                        joinHub: "Rejoindre",
                        urlExpired: "Ce lien d'invitation est expiré.",
                        urlCopied: "Le lien a bien été copié"
                    },
                    buttons: {
                        download: "Télécharger",
                    },
                    privacyPolicy: {
                        title: "Politique de confidentialité",
                        description: "Cette Politique de confidentialité décrit comment LeisureTime recueille, utilise et partage les informations personnelles des utilisateurs de notre application.",
                        informationCollected: "Informations recueillies",
                        informationCollectedDescription: "Nous pouvons collecter les informations suivantes lorsque vous utilisez notre application :",
                        userProvidedInfo: "Informations fournies par l'utilisateur : Lors de l'utilisation de l'application, vous pouvez nous fournir des informations telles que votre nom, votre adresse e-mail et d'autres informations personnelles que vous choisissez de partager.",
                        usageInfo: "Informations d'utilisation : Nous pouvons recueillir des informations sur la manière dont vous utilisez l'application, telles que les activités que vous organisez, les commentaires que vous laissez et d'autres interactions avec l'application.",
                        informationSharing: "Partage des informations",
                        informationSharingDescription: "Nous ne partageons pas vos informations personnelles avec des tiers à des fins commerciales. Les informations que vous fournissez ne seront utilisées que pour le fonctionnement de l'application LeisureTime.",
                        informationSecurity: "Sécurité des informations",
                        informationSecurityDescription: "Nous prenons des mesures de sécurité raisonnables pour protéger vos informations contre l'accès non autorisé, la divulgation, l'altération ou la destruction.",
                        policyChanges: "Modifications de cette Politique de confidentialité",
                        policyChangesDescription: "Nous nous réservons le droit de modifier cette Politique de confidentialité à tout moment. Les modifications entreront en vigueur dès leur publication sur cette page.",
                        consent: "En utilisant notre application, vous consentez à notre Politique de confidentialité. Si vous avez des questions ou des préoccupations concernant cette Politique de confidentialité, veuillez nous contacter à info@leisuretime.fr. Cette Politique de confidentialité a été mise à jour le 18/04/2024."
                    }

                }
            }
        }
    });

export default i18n;